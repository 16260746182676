@import '../Styles/constants.scss';

$header-panel-padding-left: $le-padding-md;
$header-panel-font-size: $le-font-size-xl;
$header-panel-font-size-sm: $le-font-size-md;
$header-panel-font-size-md: $le-font-size-lg;
$header-panel-padding-right: 20px;

.header-panel {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	height: $le-sidebar-item-height;
	background-color: #fafafa;
	border-bottom: $le-gray-border;

	&.header-panel--top-border {
		border-top: $le-gray-border;
	}

	.header-panel-left {
		padding-left: $header-panel-padding-left;
		
		&.header-panel-left--large-padding {
			padding-left: $le-padding-lg;
		}
	}

	.header-panel-center {
		padding-left: $header-panel-padding-left;
	}

	.header-panel-right {
		flex: 1;
		padding-right: $header-panel-padding-right;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		
		.header-panel-right__close-button {
			cursor: pointer;
			margin-left: $le-margin-2xl;
		}
		
		.header-panel-right__close-button--margin {
			@media screen and (max-width: $breakpoint-xs-screen-max-width) {
				margin-right: $le-margin-base;;
			}
		}
	}
}


.header-panel-title-icon {
	margin-right: $le-margin-md;
	color: $le-color-text-invert;
	vertical-align: middle;
}

$header-panel-title-line-height: 22px;
.header-panel-title-title {
	font-size: $header-panel-font-size;
	font-weight: $le-font-weight-medium;
	color: $le-color-text-invert;
	line-height: $header-panel-title-line-height;
	text-transform: uppercase;
	vertical-align: middle;
}

$header-panel-title-line-height-md: 19px;
.header-panel-title-title--medium {
	line-height: $header-panel-title-line-height-md;
	font-size: $header-panel-font-size-md;
	vertical-align: baseline;
}

.header-panel-title-title--small {
	font-size: $header-panel-font-size-sm;
	vertical-align: baseline;
}
